export const educationData = [
  // {
  //   id: 1,
  //   institution: `Golden Gate Int'l College`,
  //   course: "Higher Secondary Education",
  //   startYear: "2017",
  //   endYear: "2019",
  // },
  {
    id: 1,
    institution: "Coventry University",
    course: "BSc (hons) Computing",
    startYear: "2019",
    endYear: "2022",
  },
];
