export const experienceData = [
  {
    id: 1,
    company: "Intern",
    jobtitle: "ML Engineer",
    startYear: "2022",
    endYear: "",
  },
  {
    id: 2,
    company: "Web Project - Wordpress",
    jobtitle: "Web Developer",
    startYear: "2020",
    endYear: "2020",
  },
  {
    id: 3,
    company: "My Project For Meme Sharing & using ML on data",
    jobtitle: "Web Developer & ML Engineer",
    startYear: "2018",
    endYear: "",
  },
];
